import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll(
    '.logos-carousel-block__carousel'
  );

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 2,
        spaceBetween: 0,
        loop: true,
        autoplay: {
          delay: 4000,
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
          },
          1080: {
            slidesPerView: 6,
          },
        },
      });
    });
  }
});
